exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #4C97FF */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.stop-all_stop-all_1Y8P9 {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n}\n.stop-all_stop-all_1Y8P9:hover {\n    background-color: hsla(215, 100%, 65%, 0.15);\n}\n.stop-all_stop-all_1Y8P9 {\n    opacity: 0.5;\n}\n.stop-all_stop-all_1Y8P9.stop-all_is-active_3nog0 {\n    opacity: 1;\n}\n", ""]);

// exports
exports.locals = {
	"stop-all": "stop-all_stop-all_1Y8P9",
	"stopAll": "stop-all_stop-all_1Y8P9",
	"is-active": "stop-all_is-active_3nog0",
	"isActive": "stop-all_is-active_3nog0"
};