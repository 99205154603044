exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #4C97FF */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.community-button_community-button_2Lo_g {\n    -webkit-box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.15);\n            box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.15);\n}\n.community-button_community-button-icon_1IFvv {\n    height: 1.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"community-button": "community-button_community-button_2Lo_g",
	"communityButton": "community-button_community-button_2Lo_g",
	"community-button-icon": "community-button_community-button-icon_1IFvv",
	"communityButtonIcon": "community-button_community-button-icon_1IFvv"
};